@use '@angular/material' as mat;

@import 'mixins';
@import 'variables';

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

$mat-28stone-orange: (
  50: #fbe9e7,
  100: #ffccbc,
  200: #ffab91,
  300: #ff8a65,
  400: #ff7043,
  500: #de582a,
  600: #f4511e,
  700: #e64a19,
  800: #d84315,
  900: #bf360c,
  A100: #ff9e80,
  A200: #ff6e40,
  A400: #ff3d00,
  A700: #dd2c00,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$lss-primary: mat.define-palette($mat-28stone-orange);
$lss-accent: mat.define-palette(mat.$grey-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$lss-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$lss-theme: mat.define-light-theme(
  (
    color: (
      primary: $lss-primary,
      accent: $lss-accent,
      warn: $lss-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($lss-theme);

// Calling checkbox mixin to change only checkbox color
// Src: https://stackoverflow.com/a/56800237
//@include mat.checkbox-theme($lss-theme);

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: $fontFamily1;
}

.mat-snack-bar-container.background-warn {
  background-color: red;
  color: white;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.mat-progress-bar {
  min-height: 4px;
}

.mat-form-field-wrapper {
  height: $formInputHeight1;
  // padding-bottom: 30px;
  font-size: 15px;
  .mat-form-field-subscript-wrapper {
    margin-top: 5px;
  }
}

.mat-form-field-appearance-standard .mat-form-field-flex {
  padding-top: 0 !important;
}

mat-form-field {
  @include matFormField1;
}

.technical-skills {
  lss-editable-item {
    &.invalid {
      .ng-invalid {
        .mat-form-field-underline .mat-form-field-ripple {
          height: 2px;
          background: $errorTextColor !important;
        }
      }
    }
  }
}

lss-rich-text-area {
  quill-editor {
    word-break: break-word;

    &.mode-bulletList {
      .ql-editor:not(.ql-blank) p {
        display: list-item;
        padding: 5px;
        list-style-position: inside;
      }
    }
  }
}

.textCounter {
  font-family: $fontFamily1;
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  padding-top: 5px;
  text-align: right;
  color: $colorGrey8;
}

.cdk-overlay-container .mat-menu-panel {
  min-height: auto;
  position: relative;
  top: 5px;
  left: 15px;
}

.cdk-overlay-container .mat-dialog-container {
  padding: 0;
  text-align: center;
  font-size: 12px;
}

.cdk-overlay-container {
  .mat-mdc-tooltip {
    padding: 5px;
    @include mdc-container();
  }

  .mat-mdc-select-panel {
    @include mdc-container();
    mat-option {
      background: white;
      min-height: 36px;
      padding: 0 12px;
      mat-pseudo-checkbox {
        margin-right: 12px;
      }
    }
  }
}

.btn-link {
  background: none;
  border: none;
  cursor: pointer;
}

.error-text {
  color: $errorTextColor;
}

.decorative-icon {
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

snack-bar-container {
  margin: 0 !important;
  position: absolute;
  right: 30px;
  top: 100px;

  lss-notification {
    display: flex;
    padding-top: 5px;
    padding-left: 5px;
  }

  lss-icon {
    margin-right: 5px;
  }

  &.error,
  &.success,
  &.warning {
    max-width: 400px;
    border-radius: 0;

    h2 {
      margin-bottom: 4px;
    }

    mat-icon {
      font-size: 40px;
      width: 1em;
      height: 1em;
    }
  }

  &.error {
    background-color: $notificationErrorColor;
    color: white;
  }

  &.success {
    background-color: $colorSuccess;
    color: white;
  }
  &.warning {
    background-color: $warningColor;
    color: white;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.backdrop-thank-you {
  background-color: $colorGrey5;
}

mat-card-title.mat-card-title {
  font-size: 20px;
  margin-bottom: 32px;
}

mat-card-subtitle.mat-card-subtitle {
  font-size: 15px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

lss-cv {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.delete-user-body {
  color: $colorGrey8;

  span {
    color: black;
  }
}

table {
  .noData-container {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    padding: 20px 0;

    .title {
      color: black;
      margin-bottom: 16px;
    }

    .sub-title {
      color: $colorGrey9;
    }
  }
}

//From here starts shared scss for admin sections

.admin-container {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 20px;
  z-index: 3;
  white-space: nowrap;
  background-color: $bg-main;

  .main {
    margin-left: 16px;

    h1 {
      font: 400 24px/32px $fontFamily1;
      letter-spacing: normal;
      font-weight: 500;
      padding-left: 15px;
    }

    .content {
      position: relative;
      margin-bottom: 650px;
      margin-right: 15px;
      background: white;
      box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25),
        0px 2px 4px rgba(168, 168, 168, 0.5);
      resize: none;

      .action-buttons {
        display: flex;
        padding: 12px 16px;
      }
    }
  }
}

.mat-dialog-container {
  .input-block {
    padding: 20px;
    padding-bottom: 0px;
    h1,
    .title {
      font-weight: 500;
      text-align: left;
      margin-bottom: 20px;
    }
    .body {
      font-size: 15px;
      font-weight: 400;
      line-height: 30px;
      text-align: left;
      margin: 0;
      white-space: pre-line;
    }
  }

  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    padding: 24px;
  }
}

.mat-focused .mat-form-field-required-marker {
  color: $matLabel;
}

.mat-form-field.mat-form-field-invalid
  .mat-form-field-label
  .mat-form-field-required-marker {
  color: inherit !important;
}

.cdk-overlay-pane {
  &.dialog-animated-tooltip-ty {
    transform: translate(0, -100%);
  }
  &.dialog-animated-tooltip-txy {
    transform: translate(-50%, -100%);
  }
  &.dialog-animated-tooltip-ty-bottom {
    transform: translate(0, 0);
  }
  &.dialog-animated-tooltip-txy-bottom {
    transform: translate(-50%, 0);
  }
}
