$formControlSpace: 15px;

$formInputHeight1: 42px;
$formInputWidth1: 250px;
$formInputWidth3: 150px;
$formInputWidth4: 100px;
$matFormFieldWidth1: 180px;
$formButtonMaxWidth1: 150px;
$formButtonMaxWidth2: 200px;
$formInputIndent1: $formControlSpace;
$formMarginBottom1: $formControlSpace;
$formButtonHeight: 36px;
$iconWH: 24px;
$headerHeight: 64px;

$fontFamily1: Roboto, 'Helvetica Neue', sans-serif;

$colorGrey8: #757575;
$colorGrey2: #7b7b7b;
$colorGrey1: #898989;
$colorGrey9: #979797;
$colorGrey0: #a3a3a3;

$colorGrey7: #f1f1f1;
$colorGrey6: #d7d7d7;
$colorGrey5: #fafafa;
$colorGrey4: #eeeeee;
$colorGrey3: #fdfdfd;

$colorPrimary: #de582a;
$colorPrimary5: rgba(255, 255, 255, 0.2);
$colorPrimary1: #e69076;
$colorPrimary2: rgba($colorPrimary, 0.08);
$colorPrimary3: #f7f7f7;
$colorPrimary4: #ffffff;

$colorSuccess: #11ad2f;
$errorTextColor: #d80000;
$warningColor: #ff9e04;
$linkColor: #0066ff;

$colorHighlight: #ff774d;
$notificationErrorColor: #eb3700;
$nestedItemColor: $colorGrey5;
$errorContainerBackground: #fff5f5;
$borderColor: #c4c4c4;
$hoverColor: rgba(66, 133, 244, 0.08);
$bg-main: $colorGrey5;

$placeholder: #b4b4b4;
$matLabel: $colorGrey8;

$breadcrumbLinkColor: #a0a0a0;
$breadcrumbTextColor: #555555;
$breadcrumbSeparatorColor: $colorPrimary;
