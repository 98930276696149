@import 'variables';

@mixin actionsRight {
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  margin-top: $formControlSpace;
}

@mixin addEditableItemBtn {
  position: relative;
  width: $formButtonMaxWidth1;
  margin-bottom: $formControlSpace * 1.5;
  left: calc(100% - #{$formButtonMaxWidth1});
}

@mixin matFormField1 {
  width: inherit;
  min-width: inherit;
  margin-right: $formInputIndent1;
}

@mixin rightButton {
  position: absolute;
  right: 10px;
}

@mixin centerContent {
  display: flex;
  justify-content: center;
}

@mixin stretchToWidth {
  display: flex;
  width: 100%;
}

@mixin buildingIcon {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: $colorGrey4;
  background-repeat: no-repeat;
  background-position: center;
}

@mixin nestedItem {
  position: relative;
  margin-bottom: 15px;
  background: $colorGrey5;
  border-style: none;
}

$iconBorderWidth: 1px;

@mixin editableItemPlaceholder1 {
  background-color: $colorGrey5;
  width: 100%;
  height: 81px;
  margin-bottom: $formControlSpace;
}

input::placeholder {
  color: $placeholder !important;
  font-style: italic;
}
input:focus::placeholder {
  //color: transparent!important;
}

.mat-form-field,
.mat-input-element {
  span.error-msg {
    display: block;
    color: #d80000;
    font-size: 10px;
    margin-top: 9px;
  }
}

@mixin mdc-container {
  background: white;
  box-shadow: 0 2px 4px 0 rgba(168, 168, 168, 0.5);
}

@mixin mat-table-columns($columns) {
  .mat-column- {
    @each $colName, $props in $columns {
      $width: map-get($props, 'width');

      &#{$colName} {
        width: $width !important;
        min-width: $width !important;
        word-wrap: break-word !important;
      }
    }
  }
}
